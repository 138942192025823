import * as types from "./mutation-types";
import * as establishment from "../services/establishment";
import * as bookings from "../services/bookings";
import * as leads from "../services/leads";
import * as user from "../services/user";
import * as congress from "../services/congress";
import * as chat from "../services/chat";
import * as codes from "../services/codes";
import * as elastic from "../services/elastic";
import * as wit from "../services/wit";
import * as enquiries from "../services/enquiries";
import * as frontpage from "../services/frontpage";
import * as spaces from "../services/spaces";
import * as digitalKey from "../services/digitalKey";
import config from "../config";
import { EventBus } from "../services/eventBus";
import { getCurrentLanguage, isCorrectAppId } from "@/services/utils";
import UserService from "@/services/user/UserService";

let stringsDownloaded = false;

const resetStore = ({ commit, rootState }) => {
  commit(types.RESET_STORE);
};

const setIsFromPWA = ({ commit, rootState }, isFromPWA) => {
  if (rootState.isFromPWA !== isFromPWA) {
    commit(types.IS_FROM_PWA, isFromPWA);
  }
};

const setVerticalScreen = ({ commit, rootState }, vertical) => {
  commit(types.SET_VERTICAL_SCREEN, vertical);
};

const setVerticalScreenWithVideo = ({ commit, rootState }, vertical) => {
  commit(types.SET_VERTICAL_SCREEN_WITH_VIDEO, vertical);
};

const getEstablishment = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.get(
      rootState,
      (response) => {
        if (!response.cached) {
          commit(types.LOAD_ESTABLISHMENT, response.data.data);
          if (!rootState.interfaceLanguage) {
            commit(types.INTERFACE_LANGUAGE, getCurrentLanguage(rootState.establishment.languages));
          }
        }
        const establishmentPresent =
          rootState.establishment && rootState.establishment.id && rootState.establishment.name;

        if (!establishmentPresent) {
          EventBus.$emit("checkSubscription");
        }

        resolve(response.data.data);
      },
      (error) => console.error("Error getting Frontpage", error),
    );
  });
};

const createVisitor = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    if (rootState.user.userKey) {
      user.getUserData(
        rootState,
        (response) => {
          commit(types.CREATE_VISITOR, response.data.data);
          if (response.data.data.userKey) {
            commit(types.SET_USERKEY, response.data.data.userKey);
          }
          const userEstablishments = response.data.data.establishments;
          if (
            !response.data.data.pushToken ||
            (rootState.pushToken && rootState.pushToken != response.data.data.pushToken) ||
            !userEstablishments[rootState.establishment.id]
          ) {
            user.createVisitor(
              rootState,
              (response) => {
                commit(types.CREATE_VISITOR, response.data.data);
                const event = new Event("visitorCreated");
                dispatchEvent(event);
                resolve();
              },
              (error) => {
                console.error("Error creating visitor second time", error);
                reject();
              },
              data,
            );
            return;
          }
          if (response.data.data.pushToken) {
            commit(types.SET_PUSH_TOKEN, response.data.data.pushToken);
          }
          const event = new Event("visitorCreated");
          dispatchEvent(event);
          resolve();
        },
        (error) => {
          if (
            error &&
            error.length > 0 &&
            (error[0] === "AUTH_FAILED_USER_NOT_FOUND" || error[0] === "AUTH_FAILED_ESTABLISHMENT_NOT_VALID")
          ) {
            commit(types.RESET_STORE);
            if (error[0] === "AUTH_FAILED_ESTABLISHMENT_NOT_VALID") {
              commit(types.RESET_APIKEY_TO_DEFAULT);
              EventBus.$emit("resetApiKey");
            }
            user.createVisitor(
              rootState,
              (response) => {
                commit(types.CREATE_VISITOR, response.data.data);
                const event = new Event("visitorCreated");
                dispatchEvent(event);
                resolve();
              },
              (error) => {
                console.error("Error creating visitor second time", error);
                reject();
              },
              data,
            );
          } else {
            reject();
          }
        },
      );
      return;
    }
    user.createVisitor(
      rootState,
      (response) => {
        commit(types.CREATE_VISITOR, response.data.data);
        const event = new Event("visitorCreated");
        dispatchEvent(event);
        resolve();
      },
      (error) => {
        console.error("Error creating visitor", error);
        if (
          error &&
          error.length > 0 &&
          (error[0] === "AUTH_FAILED_USER_NOT_FOUND" || error[0] === "AUTH_FAILED_ESTABLISHMENT_NOT_VALID")
        ) {
          commit(types.RESET_USER_STORE);
          if (error[0] === "AUTH_FAILED_ESTABLISHMENT_NOT_VALID") {
            commit(types.RESET_APIKEY_TO_DEFAULT);
            EventBus.$emit("resetApiKey");
          }
          user.createVisitor(
            rootState,
            (response) => {
              commit(types.CREATE_VISITOR, response.data.data);
              const event = new Event("visitorCreated");
              dispatchEvent(event);
              resolve();
            },
            (error) => {
              console.error("Error creating visitor second time", error);
              reject();
            },
            data,
          );
        } else {
          reject();
        }
      },
      data,
    );
  });
};

const createLoginPMS = ({ commit, rootState }, logingFields) => {
  return new Promise((resolve, reject) => {
    user.createLoginPMS(
      rootState,
      (response) => {
        if (response?.data?.token) {
          commit(types.SET_TOKEN_USER, response.data.token);
        }
        if (response.status == 200) {
          if (response && response.data && response.data.data && response.data.data.uid) {
            commit(types.CREATE_GUEST, response.data.data);
            resolve(response.data.data);
          } else if (Array.isArray(response.data.data) && response.data.data.length > 0) {
            resolve(response.data.data);
          } else {
            if (response && response.data && response.data.errors) {
              reject(
                response.data.errors && response.data.errors.length
                  ? response.data.errors[0]
                  : "ERROR_PLEASE_TRY_AGAIN",
              );
            } else {
              reject("ERROR_PLEASE_TRY_AGAIN");
            }
          }
        } else if (response.status == 400) {
          reject("ERROR_LOGINPMS");
        } else if (response.status == 401) {
          reject("ERROR_LOGINPMS");
        } else if (response.status == 403) {
          reject("ERROR_LOGINPMS");
        } else if (response.status == 404) {
          reject("ERROR_LOGINPMS");
        } else {
          reject(
            response && response.data && response.data.errors && response.data.errors.length
              ? response.data.errors[0]
              : "ERROR_LOGINPMS",
          );
        }
      },
      (error) => {
        console.error("Error creating guest", JSON.stringify(error));
        reject(
          error.response && error.response.data && error.response.data.errors && error.response.data.errors.length
            ? error.response.data.errors[0]
            : "ERROR_LOGINPMS",
        );
      },
      logingFields,
    );
  });
};

const getFieldsLoginPms = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    user.getFieldsLoginPms(
      rootState,
      (response) => {
        if (response.status == 200) {
          if (response && response.data && response.data.loginForm) {
            resolve(response.data.loginForm);
          } else {
            if (response.errors) {
              reject(response.errors && response.errors.length ? response.errors[0] : "ERROR_PLEASE_TRY_AGAIN");
            } else {
              reject("ERROR");
            }
          }
        } else if (response.status == 401) {
          reject("ERROR");
        } else if (response.status == 403) {
          reject("ERROR");
        } else if (response.status == 404) {
          reject("ERROR");
        } else {
          reject("ERROR");
        }
      },
      (error) => {
        console.error("Error getting frontpage icon data", error);
        reject("ERROR_LOGINPMS");
      },
    );
  });
};

const getLoginType = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    user.getFieldsLoginPms(
      rootState,
      (response) => {
        if (!response || response.status != 200) {
          return reject("ERROR");
        }

        if (response?.data?.loginType) {
          return resolve(response.data);
        }

        if (response.errors) {
          reject(response.errors?.length ? response.errors[0] : "ERROR_PLEASE_TRY_AGAIN");
        } else {
          reject("ERROR");
        }
      },
      (error) => {
        reject("ERROR_LOGINPMS");
      },
    );
  });
};

const updateUserData = ({ commit, rootState }, data) => {
  const language = window.navigator.language.substring(0, 2);
  return new Promise((resolve, reject) => {
    user.updateUserData(
      rootState,
      (response) => {
        if (response.data.data && response.data.data.uid) {
          response.data.data.locale = language;
          commit(types.UPDATE_USER, response.data.data);
          resolve(response.data.data);
        } else {
          reject(response.data.errors && response.data.errors.length ? response.data.errors[0] : "ERROR");
        }
      },
      (error) => {
        console.error("Error updating user", error);
        reject(error);
      },
      data,
    );
  });
};

const createGuestAutologin = ({ commit, rootState }, data) => {
  const language = window.navigator.language.substring(0, 2);
  return new Promise((resolve, reject) => {
    user.createGuestAutologin(
      rootState,
      (response) => {
        if (response.data.data && response.data.data.uid) {
          response.data.data.locale = language;
          commit(types.CREATE_GUEST, response.data.data);
          commit(types.STORE_TWILIO_TOKEN, undefined);
          resolve(response.data.data);
        } else {
          reject(response.data.errors && response.data.errors.length ? response.data.errors[0] : "ERROR");
        }
      },
      (error) => {
        console.error("Error creating guest", error);
        reject(
          error.response && error.response.data && error.response.data.errors && error.response.data.errors.length
            ? error.response.data.errors[0]
            : error,
        );
      },
      data,
    );
  });
};

const createGuestReferral = ({ commit, rootState }, data) => {
  const language = window.navigator.language.substring(0, 2);
  return new Promise((resolve, reject) => {
    user.createGuestReferral(
      rootState,
      (response) => {
        if (response.data.data && response.data.success === true) {
          response.data.data.locale = language;
          commit(types.CREATE_GUEST, response.data.data);
          commit(types.STORE_TWILIO_TOKEN, undefined);
          resolve(response.data.data);
        } else {
          reject(response.data.errors);
        }
      },
      (error) => {
        console.error("Error creating guest from referral", error);
        reject();
      },
      data,
    );
  });
};

const logout = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    user.logout(
      rootState,
      (response) => {
        commit(types.LOGOUT, response.data.data);
        commit(types.STORE_TWILIO_TOKEN, undefined);
        resolve();
      },
      (error) => {
        console.error("Error logging out", error);
        reject();
      },
    );
  });
};

const createGuestReferralWithPS = ({ commit, rootState }, data) => {
  const language = window.navigator.language.substring(0, 2);
  return new Promise((resolve, reject) => {
    user.createGuestReferralWithPS(
      rootState,
      (response) => {
        if (response.data.data && response.data.success === true) {
          response.data.data.locale = language;
          commit(types.CREATE_GUEST, response.data.data);
          commit(types.STORE_TWILIO_TOKEN, undefined);

          if (response.data.data.userKey) {
            commit(types.SET_USERKEY, response.data.data.userKey);
          }
          const event = new Event("visitorCreated");
          dispatchEvent(event);

          resolve(response.data.data);
        } else {
          reject(response.data.errors);
        }
      },
      (error) => {
        console.error("Error creating guest from referralwithps", error);
        reject(error);
      },
      data,
    );
  });
};

const createGuestWithAccessGParam = ({ commit, rootState }, data) => {
  const language = window.navigator.language.substring(0, 2);
  return new Promise((resolve, reject) => {
    user.createGuestWithAccessGParam(
      rootState,
      (response) => {
        if (response.data.data && response.data.success === true) {
          response.data.data.locale = language;
          commit(types.CREATE_GUEST, response.data.data);

          if (response.data.data.userKey) {
            commit(types.SET_USERKEY, response.data.data.userKey);
          }
          const event = new Event("visitorCreated");
          dispatchEvent(event);

          resolve(response.data.data);
        } else {
          reject(response.data.errors);
        }
      },
      (error) => {
        console.error("Error creating guest from referralwithps", error);
        reject(error);
      },
      data,
    );
  });
};

const getActivity = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    if (!rootState.user?.cliId) {
      return reject("No need to get user activity");
    }

    user.getActivity(
      rootState,
      (response) => {
        const bookings = response.data.data;
        const reservations = [];
        const leads = [];

        bookings.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        bookings.forEach((booking) => {
          if (
            booking.id.indexOf("booking") !== -1 &&
            (booking.status === 0 ||
              booking.status === 1 ||
              booking.status === 2 ||
              booking.status === 3 ||
              booking.status === 4 ||
              booking.status === 5 ||
              booking.status === 7)
          ) {
            reservations.push(booking);
          } else if (booking.id.indexOf("lead") !== -1 || booking.id.indexOf("order") !== -1) {
            leads.push(booking);
          }
        });

        commit(types.SET_ACTIVITY, {
          reservations: reservations,
          leads: leads,
        });
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting activity", error);
        reject();
      },
    );
  });
};

const getUserInbox = async ({ commit, rootState }) => {
  try {
    if (!isCorrectAppId()) throw new Error("App id is not correct");

    const { data } = await UserService.getUserData(rootState);

    commit(types.CREATE_VISITOR, data);
    if (data.userKey) commit(types.SET_USERKEY, data.userKey);
    if (data.pushToken) commit(types.SET_PUSH_TOKEN, data.pushToken);

    return new Promise((resolve, reject) => {
      user.getUserInbox(
        rootState,
        (response) => {
          if (!response?.data?.data) {
            return reject(`Error userInbox`);
          }

          commit(types.USER_INBOX, response.data.data);

          if (response.data.data.userKey) {
            commit(types.SET_USERKEY, response.data.data.userKey);
          }

          try {
            //we take the EVENTS
            if (response.data.data.events) {
              Object.keys(response.data.data.events).forEach((key) => {
                const event = response.data.data.events[key];
                commit(types.EVENTS, event);
              });
            }
          } catch (e) {
            console.error("Error:", e);
          }

          resolve(response.data.data);
        },
        (error) => reject(`Error userInbox ${error}`),
      );
    });
  } catch (err) {
    console.error("Error getting user data", err);
  }
};

const markAlertAsRead = ({ commit, rootState }, alertId) => {
  commit(types.MARK_AS_READ, alertId);
};

const deleteAlert = ({ commit, rootState }, alertId) => {
  commit(types.DELETE_ALERT, alertId);
};

const getStrings = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    if (stringsDownloaded) {
      resolve();
      return;
    }
    if (rootState.establishment.commercialEntity) {
      stringsDownloaded = true;
    }
    establishment.getStrings(
      rootState,
      (response) => {
        if (!response.cached) {
          commit(types.GET_STRINGS, response.data);
        }
        EventBus.$emit("stringsDownloaded");
        resolve();
      },
      (error) => {
        console.error("Error getting Strings", error);
        reject();
      },
    );
  });
};

const getServices = ({ commit, rootState }, serviceType) => {
  return new Promise((resolve, reject) => {
    if (!serviceType) {
      reject();
      return;
    }
    establishment.getServices(
      rootState,
      (response) => {
        if (!response.cached) {
          commit(types.GET_SERVICES, { services: response.data.data, serviceType: serviceType });
        }
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting services", serviceType, error);
        reject(error);
      },
      serviceType,
    );
  });
};
const getServicesNoSaveDataInStore = ({ commit, rootState }, serviceType) => {
  return new Promise((resolve, reject) => {
    if (!serviceType) {
      reject();
      return;
    }
    establishment.getServices(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        reject(error);
      },
      serviceType,
    );
  });
};

const getServiceLegalTextLinks = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getServiceLegalTextLinks(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data,
    );
  });
};

const getLegalText = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getLegalText(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data,
    );
  });
};

const getServiceSubscriptionForm = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getServiceSubscriptionForm(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data,
    );
  });
};

const getServiceTypeConfigurationByType = ({ state, commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getServiceTypeConfigurationByType(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        console.error("Error getting occupation: ", error);
        reject(error);
      },
      data,
    );
  });
};

const getMembers = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getMembers(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data,
    );
  });
};

const getMember = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getMember(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data,
    );
  });
};

const createMember = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.createMember(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data,
    );
  });
};

const signMember = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.signMember(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data,
    );
  });
};

const getTripAdvisorServices = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.getTripAdvisorServices(
      rootState,
      (response) => {
        commit(types.GET_TRIPADVISOR_SERVICES, response.data.data);
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting external services", error);
        reject(error);
      },
    );
  });
};

const getCategories = ({ commit, rootState }, serviceType) => {
  return new Promise((resolve, reject) => {
    establishment.getCategories(
      rootState,
      (response) => {
        if (!response.cached) {
          commit(types.GET_CATEGORIES, { categories: response.data.data, serviceType: serviceType });
        }
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting service categories", serviceType, error);
        reject();
      },
      serviceType,
    );
  });
};

const getService = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    if (!data || !data.serviceId) {
      reject("serviceId not found");
      return;
    }
    establishment.getService(
      rootState,
      (response) => {
        if (!response.cached) {
          if (data.serviceType === "custom") {
            commit(types.CUSTOM_SERVICES, response.data.data);
          } else if (data.serviceType && data.serviceType.length > 0) {
            commit(types.STORE_ONE_SERVICE, { service: response.data.data, serviceType: data.serviceType });
          }
        }
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting service", error);
        reject();
      },
      data.serviceType,
      data.serviceId,
    );
  });
};
const getServicePublicId = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    if (!data || !data.servicePublicId) {
      reject();
      return;
    }
    establishment.getServicePublicId(
      rootState,
      (response) => {
        if (data.serviceType === "custom") {
          commit(types.CUSTOM_SERVICES, response.data.data);
        } else if (data.serviceType && data.serviceType.length > 0) {
          commit(types.STORE_ONE_SERVICE, { service: response.data.data, serviceType: data.serviceType });
        }
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting service", error);
        reject();
      },
      data.serviceType,
      data.servicePublicId,
    );
  });
};

const getServiceCatalogues = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getServiceCatalogues(
      rootState,
      (response) => {
        resolve(response.data.data);
        commit(types.STORE_CATALOGUES, { serviceId: data.serviceId, catalogues: response.data.data });
      },
      (error) => {
        console.error("Error getting service catalogues", error);
        reject(error);
      },
      data.serviceType,
      data.serviceId,
    );
  });
};

const getServiceItems = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getServiceItems(
      rootState,
      (response) => {
        commit(types.GET_SERVICE_ITEMS, { serviceItems: response.data.data.items, serviceId: data.serviceId });
        resolve(response.data.data.items);
      },
      (error) => {
        console.error("Error getting service items", error);
        reject();
      },
      data.serviceType,
      data.serviceId,
    );
  });
};
const getServiceItem = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getServiceItem(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting service items", error);
        reject();
      },
      data.serviceType,
      data.serviceId,
      data.itemId,
    );
  });
};

const getServiceItemCategories = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getServiceItemCategories(
      rootState,
      (response) => {
        commit(types.GET_SERVICE_ITEM_CATEGORIES, {
          serviceItemCategories: response.data.data.categories,
          serviceId: data.serviceId,
        });
        resolve(response.data.data.categories);
      },
      (error) => {
        console.error("Error getting service item categories", error);
        reject();
      },
      data.serviceType,
      data.serviceId,
    );
  });
};

const getCategory = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getCategory(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting category", error);
        reject();
      },
      data.serviceType,
      data.category,
    );
  });
};

const getCategoryCalendar = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getCategoryCalendar(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting category calendar", error);
        reject();
      },
      data.serviceType,
      data.category,
      data.query,
    );
  });
};

const getShowsCalendar = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getShowsCalendar(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting shows calendar", error);
        reject();
      },
      data.query,
    );
  });
};

const getProductAttributes = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getProductAttributes(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting products attributes", error);
        reject();
      },
      data,
    );
  });
};

const getProduct = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getProduct(
      rootState,
      (response) => {
        if (response?.data?.data) {
          return resolve(response.data.data);
        }

        reject();
      },
      (error) => {
        console.error("Error getting service", error);
        reject();
      },
      data,
    );
  });
};

const getProducts = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getProducts(
      rootState,
      (response) => {
        if (response?.data?.data?.products) {
          if (!response.cached) {
            commit(types.GET_PRODUCTS, { products: response.data.data.products, serviceId: data.serviceId });
          }
          resolve(
            response.data && response.data.data && response.data.data.products ? response.data.data.products : [],
          );
        } else {
          reject();
        }
      },
      (error) => {
        console.error("Error getting service", error);
        reject();
      },
      {
        serviceType: data.serviceType,
        serviceId: data.serviceId,
      },
    );
  });
};

const getProductCategories = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getProductCategories(
      rootState,
      (response) => {
        if (response?.data?.data?.categories) {
          if (!response.cached) {
            commit(types.GET_PRODUCT_CATEGORIES, {
              categories: response.data.data.categories,
              serviceId: data.serviceId,
            });
          }
          resolve(
            response.data && response.data.data && response.data.data.categories ? response.data.data.categories : [],
          );
        } else {
          reject();
        }
      },
      (error) => {
        console.error("Error getting service", error);
        reject();
      },
      data.serviceType,
      data.serviceId,
    );
  });
};

const reserveWithProduct = ({ commit, rootState }, bookingData) => {
  return new Promise((resolve, reject) => {
    bookings.reserveProduct(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error booking with product", error);
        reject();
      },
      bookingData,
    );
  });
};

const reserveService = ({ commit, rootState }, bookingData) => {
  return new Promise((resolve, reject) => {
    bookings.reserveService(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error booking service", error);
        reject();
      },
      bookingData,
    );
  });
};

const joinActivity = ({ commit, rootState }, activityData) => {
  return new Promise((resolve, reject) => {
    bookings.joinActivity(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error joining activity", error);
        reject();
      },
      activityData,
    );
  });
};

const joinActivityFromPWA = ({ commit, rootState }, activityData) => {
  return new Promise((resolve, reject) => {
    bookings.joinActivityFromPWA(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error joining activity", error);
        reject();
      },
      activityData,
    );
  });
};

const getBookingForm = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    bookings.getForm(
      rootState,
      (response) => {
        if (response.data.data.length) {
          resolve(response.data.data);
        } else if (response.data.errors.length > 0 && response.data.errors[0] === "LOGGED_USER_NEEDED") {
          reject(response.data.errors[0]);
        } else {
          reject("KEY_ERROR_UNATHORIZED");
        }
      },
      (error) => {
        console.error("Error getting booking form", error);
        let key = "KEY_ERROR_OUT_OF_SERVICE";
        if (error && error.length > 0 && error[0] === "NO_AVAILABLE_DAYS_FOR_BOOKING") {
          key = "NO_AVAILABLE_DAYS_FOR_BOOKING";
        } else if (error && error.length > 0 && error[0] === "LOGGED_USER_NEEDED") {
          key = error[0];
        }
        reject(key);
      },
      data,
    );
  });
};

const getDateNow = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.getDateNow(
      rootState,
      (response) => {
        if (response.data && response.data.data && response.data.success) {
          commit(types.SET_DATE_NOW, response.data.data);
          resolve(response.data.data);
        } else if (response.data && response.data.errors && !response.data.success) {
          reject(response.data.errors[0]);
        } else {
          reject("ERROR");
        }
      },
      (error) => {
        reject(error);
      },
    );
  });
};

const leadWithProduct = ({ commit, rootState }, leadData) => {
  return new Promise((resolve, reject) => {
    leads.createWithProduct(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error creating lead", error);
        reject();
      },
      leadData,
    );
  });
};

const getBookingConfig = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getBookingConfig(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting booking config", error);
        reject();
      },

      data.serviceType,
      data.serviceId,
    );
  });
};

const getMapPoints = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.getMapPoints(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting map points", error);
        reject();
      },
    );
  });
};

const getMapCategories = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.getMapCategories(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting map categories", error);
        reject();
      },
    );
  });
};

const getCongressCalendar = ({ commit, rootState }, congressId) => {
  return new Promise((resolve, reject) => {
    congress.getCongressCalendar(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting congress calendar", error);
        reject();
      },
      congressId,
    );
  });
};

const getUrls = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getUrls(
      rootState,
      (response) => {
        commit(types.SET_APP_URLS, response.data.data);
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting urls", error);
        reject();
      },
      data,
    );
  });
};

const getDeepLinks = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getDeepLinks(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting deep links", error);
        reject();
      },
      data,
    );
  });
};

const getDeepLinkData = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getDeepLinkData(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        console.error("Error getting deep links", error);
        reject();
      },
      data,
    );
  });
};

const sendPostToUrl = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.sendPostToUrl(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        console.error("Error getting post data", error, data);
        reject(error);
      },
      data.url,
      data.body,
      data.headers,
    );
  });
};

const externalWebBooking = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    bookings.externalWebBooking(
      rootState,
      (response) => {
        if (response.data && response.data.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      },
      (error) => {
        console.error("externalWebBooking err: ", error);
        reject(error);
      },
    );
  });
};

const sendGetToUrl = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.sendGetToUrl(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        console.error("Error getting get data", error, data);
        reject(error);
      },
      data.url,
      data.headers,
    );
  });
};

const getFrontpageIcon = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.getFrontpageIcon(
      rootState,
      (response) => {
        resolve(response.data);
        if (response.data.navBarTransparent) {
          commit(types.FRONTPAGE_ICON, response.data.navBarTransparent);
        } else {
          commit(types.FRONTPAGE_ICON, 0);
        }
      },
      (error) => {
        console.error("Error getting post data", error);
        reject(error);
      },
    );
  });
};

const getSurvey = ({ commit, rootState }, idSurvey) => {
  return new Promise((resolve, reject) => {
    establishment.getSurvey(
      rootState,
      (response) => {
        resolve(Object.assign({}, response.data.data)); // To prevent store mutation issues
        commit(types.SURVEY, response.data.data);
      },
      (error) => {
        console.error("Error getting post survey data", error);
        reject(error);
      },
      idSurvey,
    );
  });
};

const getSurveys = ({ commit, rootState }, idSurvey) => {
  return new Promise((resolve, reject) => {
    establishment.getSurveys(
      rootState,
      (response) => {
        commit(types.SURVEYS, response.data.data);
        resolve(response.data.data); // To prevent store mutation issues
      },
      (error) => {
        console.error("Error getting surveys data", error);
        reject(error);
      },
      idSurvey,
    );
  });
};

const getSurveyQuestions = ({ commit, rootState }, idSurvey) => {
  return new Promise((resolve, reject) => {
    establishment.getSurveyQuestions(
      rootState,
      (response) => {
        resolve(Object.assign({}, response.data.data)); // To prevent store mutation issues
      },
      (error) => {
        console.error("Error getting post survey data", error);
        reject(error);
      },
      idSurvey,
    );
  });
};

const sendSurvey = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.sendSurvey(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        console.error("Error sending survey data", error, data);
        reject(error);
      },
      data,
    );
  });
};

const sendSurveyV2 = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.sendSurveyV2(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        console.error("Error sending survey v2 data", error, data);
        reject(error);
      },
      data,
    );
  });
};

const getSurveysV2Replies = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.getSurveysV2Replies(
      rootState,
      (response) => {
        resolve(response.data.data);
        commit(types.SURVEY_REPLIES, response.data.data);
      },
      (error) => {
        console.error("Error getting survey v2 replies", error);
        reject(error);
      },
    );
  });
};

const getSurveysExternalReviewsConfiguration = ({ commit, rootState }, establishmentId) => {
  return new Promise((resolve, reject) => {
    establishment.getSurveysExternalReviewsConfiguration(
      rootState,
      (response) => {
        resolve(response.data.data);
        commit(types.SURVEY_EXTERNAL_REVIEWS, response.data.data);
      },
      (error) => {
        console.error("Error getting survey v2 replies", error);
        reject(error);
      },
      establishmentId,
    );
  });
};

const hideSurvey = ({ commit, rootState }, idSurvey) => {
  commit(types.HIDE_SURVEY, idSurvey);
};

const saveWelcomeSurveyResponseDate = ({ commit, rootState }, date) => {
  commit(types.SET_WELCOME_SURVEY_DATE, date);
};

const storeChatMessages = ({ commit, rootState }, chatMessages) => {
  commit(types.STORE_CHAT_MESSAGES, chatMessages);
};

const addChatMessage = ({ commit, rootState }, chatMessage) => {
  commit(types.ADD_CHAT_MESSAGE, chatMessage);
};

const removeLastChatMessage = ({ commit, rootState }) => {
  commit(types.REMOVE_LAST_MESSAGE);
};

const getChatToken = ({ commit, rootState }, userData) => {
  return new Promise((resolve, reject) => {
    chat.getChatToken(
      rootState,
      (response) => {
        resolve(response.data);
        if (response.data && response.data.token) {
          commit(types.STORE_TWILIO_TOKEN, response.data);
        }
      },
      (error) => {
        console.error("Error getting chat token", error);
        reject(error);
      },
      userData,
    );
  });
};

const setPendingTermsConditions = ({ commit, rootState }, data) => {
  if (data.establishment) {
    commit(types.PENDING_TERMS_CONDITIONS, data);
  }
};

const linkCongress = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    congress.linkCongress(
      rootState,
      (response) => {
        //response.data -> // {"data":{"congress":351,"establishment":947,"uid":1974344},"errors":[],"success":true}
        if (response && response.data && response.data.data && response.data.data.congress) {
          resolve(response.data.data);
        } else {
          reject(response.data.errors && response.data.errors.length ? response.data.errors[0] : "ERROR");
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject();
      },
      data.codeOrId,
      data.isPublic,
    );
  });
};

const unlinkCongress = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    congress.unlinkCongress(
      rootState,
      (response) => {
        if (response && response.data && response.data.data && response.data.data.congress) {
          resolve(response.data.data);
        } else {
          reject(response.data.errors && response.data.errors.length ? response.data.errors[0] : "ERROR");
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject();
      },
      data.codeOrId,
      data.isPublic,
    );
  });
};

const getEventList = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    congress.getEventList(
      rootState,
      (response) => {
        if (response && response.data && response.data.data) {
          commit(types.PUBLIC_EVENTS, response.data.data);
          resolve(response.data.data);
        } else {
          reject();
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject();
      },
    );
  });
};

const getEventInfo = ({ commit, rootState }, idCongress) => {
  return new Promise((resolve, reject) => {
    congress.getEventInfo(
      rootState,
      (response) => {
        if (response && response.data && response.data.data && response.data.data.id) {
          resolve(response.data.data);
        } else {
          reject(response.data.errors && response.data.errors.length ? response.data.errors[0] : "ERROR");
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject(error);
      },
      idCongress,
    );
  });
};

const getCongressList = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    congress.getCongressList(
      rootState,
      (response) => {
        if (response && response.data && response.data.data) {
          commit(types.PUBLIC_EVENTS, response.data.data);
          resolve(response.data.data);
        } else {
          reject();
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject();
      },
    );
  });
};

const getCongress = ({ commit, rootState }, idCongress) => {
  return new Promise((resolve, reject) => {
    congress.getCongress(
      rootState,
      (response) => {
        if (response && response.data && response.data.data && response.data.data.id) {
          resolve(response.data.data);
        } else {
          reject(response.data.errors && response.data.errors.length ? response.data.errors[0] : "ERROR");
        }
      },
      (error) => {
        reject(error);
      },
      idCongress,
    );
  });
};

const getCongressActivityDetail = ({ commit, rootState }, idCongress) => {
  return new Promise((resolve, reject) => {
    congress.getCongressActivityDetail(
      rootState,
      (response) => {
        if (response && response.data && response.data.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.errors && response.data.errors.length ? response.data.errors[0] : "ERROR");
        }
      },
      (error) => {
        reject(error);
      },
      idCongress,
    );
  });
};

const decodeEstablishmentHash = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    codes.decodeHash(
      rootState,
      (response) => {
        if (config.botApiCredentials.bearer && config.botApiCredentials.bearer.length > 0) {
          commit(types.SET_BOT_TOKEN, config.botApiCredentials.bearer);
        }
        if (config.cmsApiHostCredentials.bearer && config.cmsApiHostCredentials.bearer.length > 0) {
          commit(types.SET_CMS_TOKEN, config.cmsApiHostCredentials.bearer);
        }

        if (response?.establishment) {
          return resolve(response);
        }

        reject(response?.errors?.length ? response.errors[0] : "ERROR");
      },
      (error) => {
        console.error("Error: ", error);
        reject(error);
      },
      data.establishmentHash,
      data.essentialBearer,
    );
  });
};

const decodeEstablishmentHashAndStoreData = ({ commit, rootState }, establishmentHash) => {
  return new Promise((resolve, reject) => {
    codes.decodeHash(
      rootState,
      (response) => {
        if (response && response.establishment) {
          commit(types.CHANGE_ESTABLISHMENT, { establishmentId: response.establishment });
          commit(types.SET_ESTABLISHMENT_HASH, establishmentHash);
        }

        getStrings({ commit, rootState });

        if (config.botApiCredentials.bearer && config.botApiCredentials.bearer.length > 0) {
          commit(types.SET_BOT_TOKEN, config.botApiCredentials.bearer);
        }

        if (config.cmsApiHostCredentials.bearer && config.cmsApiHostCredentials.bearer.length > 0) {
          commit(types.SET_CMS_TOKEN, config.cmsApiHostCredentials.bearer);
        }

        if (response && response.establishment) {
          commit(types.IS_FROM_PWA, true);
          commit(types.CHANGE_ESTABLISHMENT, { establishmentId: response.establishment });
          commit(types.SET_ESTABLISHMENT_HASH, establishmentHash);
          frontpage.get(
            rootState,
            (response) => {
              if (response && response.data) {
                commit(types.STORE_FRONTPAGE_DATA, response.data);
                if (response.data.mainColor) {
                  commit(types.UPDATE_MAIN_COLOR, response.data.mainColor);
                } else {
                  commit(types.UPDATE_MAIN_COLOR, "ffffff");
                }
                document.getElementsByTagName("html")[0].style.setProperty("--main-color", rootState.mainColor);
                document.getElementsByTagName("html")[0].style.setProperty("--cc-btn-primary-bg", rootState.mainColor);

                if (response.data.navBarTransparent) {
                  commit(types.FRONTPAGE_ICON, response.data.navBarTransparent);
                } else {
                  commit(types.FRONTPAGE_ICON, 0);
                }
              }
            },
            (error) => {
              console.error("Error getting Frontpage type on FrontpageFactory", error);
            },
          );
          establishment.get(
            rootState,
            (response2) => {
              if (
                response2.data &&
                response2.data.data &&
                response2.data.data.requestChat &&
                response2.data.data.requestChat.allowed === true
              ) {
                const event = new Event("enquiriesEnabled");
                dispatchEvent(event);
              }
              createVisitor({ commit, rootState });
              const establishmentPresent =
                rootState.establishment && rootState.establishment.id && rootState.establishment.name;
              commit(types.LOAD_ESTABLISHMENT, response2.data.data);
              if (!rootState.interfaceLanguage) {
                commit(types.INTERFACE_LANGUAGE, getCurrentLanguage(rootState.establishment.languages));
              }
              if (!establishmentPresent) {
                EventBus.$emit("checkSubscription");
              }
              resolve(response);
            },
            (error) => {
              console.error("Error getting establishment", error);
              reject(response.errors && response.errors.length ? response.errors[0] : "ERROR");
            },
          );
        } else {
          reject(response.errors && response.errors.length ? response.errors[0] : "ERROR");
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject(error);
      },
      establishmentHash,
      true,
    );
  });
};

const decodeCongressHash = ({ commit, rootState }, congressHash) => {
  return new Promise((resolve, reject) => {
    codes.decodeHashCongress(
      rootState,
      (response) => {
        if (response && response.data && response.data.congress) {
          resolve(response.data.congress);
        } else {
          reject();
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject(error);
      },
      congressHash,
    );
  });
};

const sendStatusAlert = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    user.sendStatusAlert(
      rootState,
      (response) => {},
      (error) => {},
      data,
    );
  });
};

const getEventsCalendar = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getEventsCalendar(
      rootState,
      (response) => {
        if (response && response.data && response.data.data) {
          commit(types.EVENTS_CALENDAR, {
            data: response.data.data,
            idCategory: data.category,
          });
          resolve(response.data.data);
        } else {
          reject();
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject();
      },
      data.serviceType,
      data.category,
      data.query,
    );
  });
};

const findEnabledModules = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.findEnabledModules(
      rootState,
      (response) => {
        if (response && response.data && response.data.data) {
          commit(types.ENABLED_MODULES, response.data.data);
          resolve(response.data.data);
        } else {
          reject();
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject();
      },
    );
  });
};

const searchElastic = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    elastic.searchElastic(
      rootState,
      (response) => {
        if (response && response.data && response.data.hits && response.data.hits.hits) {
          resolve(response.data.hits.hits);
        } else {
          reject("No results");
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject("No results");
      },
      data,
    );
  });
};

const searchElasticPredefined = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    elastic.searchPredefined(
      rootState,
      (response) => {
        if (response && response.data && response.data.hits && response.data.hits.hits) {
          resolve(response.data.hits.hits);
        } else {
          reject("No results");
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject("No results");
      },
      data,
    );
  });
};

const searchElasticAllServicesOrProducts = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    elastic.searchAllServicesOrProducts(
      rootState,
      (response) => {
        if (response && response.data && response.data.hits && response.data.hits.hits) {
          response.data.hits.text = data.text;
          resolve(response.data.hits);
        } else {
          reject("No results");
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject("No results");
      },
      data,
    );
  });
};

const getClusterEstablishmentId = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.getClusterEstablishmentId(
      rootState,
      (response) => {
        commit(types.ESTABLISHMENT_ID_CLUSTER, response.data._id);
        resolve(response.data);
        EventBus.$emit("clusterEstablishmentIdLoaded");
      },
      (error) => {
        console.error("Error getting cluster establishment id: ", error);
        reject(error);
      },
    );
  });
};

const getEstablishmentAssociatedApps = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getEstablishmentAssociatedApps(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        console.error("Error getting associated apps: ", error);
        reject(error);
      },
    );
  });
};

const sendCommentToTransaction = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    bookings.sendCommentToTransaction(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        console.error("Error sending comment: ", error);
        reject(error);
      },
      data,
    );
  });
};

const getTransactionDetail = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    bookings.getTransactionDetail(
      rootState,
      (response) => {
        resolve(response.data);
        if (response && response.data && response.data.data && response.data.data.id) {
          commit(types.UPDATE_REQUEST, response.data.data);
        }
      },
      (error) => {
        console.error("Error getting transaction detail: ", error);
        reject(error);
      },
      data,
    );
  });
};

const cancelBooking = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    bookings.cancelBooking(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        console.error("Error canceling booking: ", error);
        reject(error);
      },
      data,
    );
  });
};

const searchWit = ({ commit, rootState }, text) => {
  return new Promise((resolve, reject) => {
    wit.searchWit(
      rootState,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
      text,
    );
  });
};

const setGroupingTab = ({ commit, rootState }, tab) => {
  commit(types.SET_GROUPING_TAB, tab);
};

const setCityGuideTab = ({ commit, rootState }, tab) => {
  commit(types.SET_CITY_GUIDE_TAB, tab);
};

const setGroupingPointIndex = ({ commit, rootState }, index) => {
  commit(types.SET_GROUPING_POINT_INDEX, index);
};

const setGroupingView = ({ commit, rootState }, view) => {
  commit(types.SET_GROUPING_VIEW, view);
};

const setCityGuideView = ({ commit, rootState }, view) => {
  commit(types.SET_CITY_GUIDE_VIEW, view);
};

const resetGrouping = ({ commit, rootState }) => {
  commit(types.RESET_GROUPING);
};

const getEstablishmentWeather = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.getWeather(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

const getPrestayUrlInfo = ({ commit, rootState }, code) => {
  return new Promise((resolve, reject) => {
    codes.getPrestayUrlInfo(
      rootState,
      (response) => {
        if (config.botApiCredentials.bearer && config.botApiCredentials.bearer.length > 0) {
          commit(types.SET_BOT_TOKEN, config.botApiCredentials.bearer);
        }
        if (config.cmsApiHostCredentials.bearer && config.cmsApiHostCredentials.bearer.length > 0) {
          commit(types.SET_CMS_TOKEN, config.cmsApiHostCredentials.bearer);
        }

        if (response && response.data && response.data.data && response.data.data.decodedEstablishment) {
          resolve(response.data);
        } else {
          reject(response.data.errors && response.data.errors.length ? response.data.errors[0] : "ERROR");
        }
      },
      (error) => {
        console.error("Error: ", error);
        reject(error);
      },
      code,
    );
  });
};

const createNewEnquiry = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    enquiries.createEnquiry(
      rootState,
      (response) => {
        if (response.data && response.data.data && response.data.success) {
          resolve(response.data.data);
        } else if (response.data && response.data.errors && !response.data.success) {
          reject(response.data.errors[0]);
        } else {
          reject("ERROR_CREATING_ENQUIRY");
        }
      },
      (error) => {
        reject(error);
      },
      data.service,
      data.itemType,
      data.itemId,
      data.comment,
    );
  });
};

const subscriptionActive = ({ commit, rootState }, establishmentId) => {
  return new Promise((resolve, reject) => {
    establishment.subscriptionActive(
      rootState,
      ({ data }) => {
        if (!data) {
          return reject({ active: false });
        }

        commit(types.STARTER_PLAN, data.starterPlan);
        resolve(data);
      },
      () => {
        reject({ active: false });
      },
      establishmentId,
    );
  });
};

const unlockAppWithCode = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.unlockAppWithCode(
      rootState,
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject({ active: false });
        }
      },
      (error) => {
        reject({ active: false });
      },
      data.establishmentId,
      data.code,
    );
  });
};

const getCredencialDigitalKey = ({ commit, rootState }, logingFields) => {
  return new Promise((resolve, reject) => {
    digitalKey.getCredencialDigitalKey(
      rootState,
      (response) => {
        if (response.status == 200) {
          if (response.data && response.data.length && (!response.errors || response.errors.length == 0)) {
            resolve(response.data);
          } else if ((response.data && response.data.length == 0) || !response.data) {
            reject("KEY_ERROR_NO_KEYS");
          } else {
            if (response.errors) {
              reject(response.errors && response.errors.length ? response.errors[0] : "KEY_ERROR_OUT_OF_SERVICE");
            } else {
              reject("KEY_ERROR_OUT_OF_SERVICE");
            }
          }
        }
      },
      (error) => {
        if (error?.status == 401) {
          reject("KEY_ERROR_UNATHORIZED");
        } else if (error?.status == 403) {
          reject("KEY_ERROR_CREDENCIAL");
        } else if (error?.status == 404) {
          reject("KEY_ERROR_NO_KEYS"); //error no keys assaabloy
        } else {
          reject("KEY_ERROR_OUT_OF_SERVICE");
        }
      },
      logingFields,
    );
  });
};

const sendLogDigitalKey = ({ commit, rootState }, type, binary) => {
  const infoLog = {
    country: rootState.country,
    language: rootState.language,
    establishment: rootState.establishment.id,
    userKey: rootState.user.userKey,
    devicekey: rootState.user.deviceKey,
    deviceUid: rootState.deviceUid,
    room: rootState.user.room,
    completeName: rootState.user.name,
    checkIn: rootState.user.checkIn,
    checkOut: rootState.user.checkOut,
    log: type,
    binary: binary,
  };
  return new Promise((resolve, reject) => {
    digitalKey.sendLogDigitalKey(
      rootState,
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
      infoLog,
    );
  });
};

const getDigitalKey = ({ commit, rootState }, logingFields) => {
  return new Promise((resolve, reject) => {
    digitalKey.getDigitalKey(
      rootState,
      (response) => {
        if (response.status == 200) {
          if (response.data && (!response.errors || response.errors.length == 0)) {
            resolve(response.data);
          } else {
            if (response.errors) {
              reject(response.errors && response.errors.length ? response.errors[0] : "KEY_ERROR_OUT_OF_SERVICE");
            } else {
              reject("KEY_ERROR_OUT_OF_SERVICE");
            }
          }
        }
      },
      (error) => {
        if (error?.status == 401) {
          reject("KEY_ERROR_UNATHORIZED");
        } else if (error?.status == 403) {
          reject("KEY_ERROR_CREDENCIAL");
        } else if (error?.status == 404) {
          reject("KEY_ERROR_OUT_OF_SERVICE");
        } else {
          reject("KEY_ERROR_OUT_OF_SERVICE");
        }
      },
      logingFields,
    );
  });
};

const getSpaceReservationFields = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceReservationFields(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceId,
    );
  });
};

const getSpaceReservationFieldsOrder = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceReservationFieldsOrder(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceId,
    );
  });
};

const getSpaceInterval = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceInterval(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceId,
    );
  });
};

const getSpaceByService = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceByService(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceId,
    );
  });
};
const getSpaceServiceSummary = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceServiceSummary(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceId,
    );
  });
};

const getSpaceDaySchedule = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceDaySchedule(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceId,
      data.date,
    );
  });
};
const getSpacesTurns = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpacesTurns(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data,
    );
  });
};
const getSpaceDayScheduleServiceList = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceDayScheduleServiceList(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
          commit("SET_SCHEDULES_SERVICES", response?.data?.schedules);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceList,
      data.date,
    );
  });
};

const getSpaceScheduleDays = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceScheduleDays(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceId,
      data.date,
      data.numberDays,
    );
  });
};

const getSpaceReservationList = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceReservationList(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceId,
    );
  });
};

const getSpaceCombinationInfo = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceCombinationInfo(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceId,
      data.spaceCombinationId,
    );
  });
};

const cancelSpaceBooking = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.cancelSpaceBooking(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data.serviceId,
      data.bookingId,
    );
  });
};

const sendSpacePostToUrl = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.sendSpacePostToUrl(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject();
        }
      },
      (error) => {
        reject(error);
      },
      data.url,
      data.body,
    );
  });
};

const getSpaceConfigurationByService = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceConfigurationByService(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
          if (response.data.policyAndText) {
            commit("SET_POLICY_TEXT", response.data.policyAndText);
          }
        } else {
          reject();
        }
      },
      (error) => {
        reject(error);
      },
      data.serviceId,
    );
  });
};
const getSpaceConfigurationByType = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceConfigurationByType(
      rootState,
      (response) => {
        if (response?.data?.results) {
          resolve(response.data.results);
        } else {
          reject();
        }
      },
      (error) => {
        reject(error);
      },
      data.serviceType,
    );
  });
};
const getSpaceRestrictionsByService = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceRestrictionsByService(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject();
        }
      },
      (error) => {
        reject(error);
      },
      data.serviceId,
    );
  });
};
const getSpaceMultiserviceRestrictionsByService = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceMultiserviceRestrictionsByService(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject();
        }
      },
      (error) => {
        reject(error);
      },
      data.serviceId,
    );
  });
};

const storeOfflineMessage = ({ commit, rootState }, data) => {
  commit(types.STORE_REQUEST_CHAT_OFFLINE_MESSAGE, data);
};

const addPromoAsSeen = ({ commit, rootState }, promoId) => {
  commit(types.SET_PROMO_HAS_BEEN_SEEN, promoId);
};

const getCorporateContents = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    establishment.getCorporateContents(
      rootState,
      (response) => {
        resolve(response.data.data);
      },
      (error) => {
        reject(error);
      },
      data,
    );
  });
};

const setMiniclubTutorInfo = ({ commit, rootState }, info) => {
  delete info.guardianPhoto;
  commit(types.SET_MINICLUB_TUTOR_INFO, info);
};

const setDirectoryTab = ({ commit, rootState }, tab) => {
  commit(types.SET_DIRECTORY_TAB, tab);
};

const getGDPRConfiguration = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    establishment.getGDPRConfiguration(
      rootState,
      (response) => {
        commit(types.SET_GDPR_CONFIGURATION, response.data);
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

const getPaymentPage = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getPaymentPage(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data.orderId,
      data.bookingId,
      data.service,
      data.serviceType,
    );
  });
};

const getPaymentIntent = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getPaymentIntent(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data.orderId,
      data.bookingId,
      data.service,
      data.serviceType,
    );
  });
};

const getSpaceOrder = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.getSpaceOrder(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data.orderId,
      data.bookingId,
      data.service,
    );
  });
};

const setServiceCatalogueSegmentationWatched = ({ commit, rootState }, data) => {
  commit(types.CATALOGUES_SEGMENTATION, data);
};

const setLanguage = ({ commit, rootState }, language) => {
  commit(types.INTERFACE_LANGUAGE, language);
};

const getGuestConsents = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    user.getGuestConsents(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
    );
  });
};
const updateGuestConsents = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    user.updateGuestConsents(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      data,
    );
  });
};

const setPSCode = ({ commit, rootState }, psCode) => {
  commit(types.PRESTAY_CODE, psCode);
};

const setAppWelcomeShow = ({ commit }, value) => {
  commit(types.APP_WELCOME_SHOW, value);
};

const setWelcomeShowActive = ({ commit }, value) => {
  commit(types.WELCOME_SHOW_ACTIVE, value);
};

const setStatuswelcomeShowVisible = ({ commit }, value) => {
  commit(types.WELCOME_SHOW_VISIBLE, value);
};

const getCampaignUrl = ({ commit, rootState }, id) => {
  return new Promise((resolve, reject) => {
    establishment.getCampaignUrl(
      rootState,
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      id,
    );
  });
};

const getMultiEstablishment = ({ commit, rootState }, id) => {
  return new Promise((resolve, reject) => {
    establishment.getMultiEstablishment(
      rootState,
      (response) => {
        if (response.data.data) {
          commit("SET_MULTI_ESTABLISHMENT", response.data.data);
        }
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
      id,
    );
  });
};

const setServiceOrderingFunnelCache = ({ commit }, data) => {
  if (!data.serviceId || !data.cart) {
    return;
  }
  commit(types.SERVICE_ORDERING_FUNNEL_CACHE, data);
};

const deleteServiceOrderingFunnelCache = ({ commit }, serviceId) => {
  if (!serviceId) {
    return;
  }
  commit(types.DELETE_SERVICE_ORDERING_FUNNEL_CACHE, serviceId);
};

const paymentsEnabledForService = ({ commit, rootState }, data) => {
  return new Promise((resolve, reject) => {
    spaces.paymentsEnabledForService(
      rootState,
      (response) => {
        if (response?.data) {
          resolve(response.data);
        } else {
          reject("NOT_AVAILABLE");
        }
      },
      (error) => {
        if (!error) {
          error = "NOT_AVAILABLE";
        }
        reject(error);
      },
      data,
    );
  });
};

const getAppDataByApiKey = ({ commit, rootState }, apiKey) => {
  return new Promise((resolve, reject) => {
    establishment.getAppDataByApiKey(
      rootState,
      (response) => {
        resolve(response?.data);
      },
      (error) => {
        reject(error);
      },
      apiKey,
    );
  });
};

export {
  resetStore,
  setIsFromPWA,
  setVerticalScreen,
  setVerticalScreenWithVideo,
  getEstablishment,
  createVisitor,
  updateUserData,
  createGuestAutologin,
  createGuestReferral,
  createGuestReferralWithPS,
  createGuestWithAccessGParam,
  logout,
  getUserInbox,
  markAlertAsRead,
  getActivity,
  getStrings,
  getServices,
  getServiceSubscriptionForm,
  getServiceLegalTextLinks,
  getLegalText,
  getMembers,
  getMember,
  createMember,
  signMember,
  setMiniclubTutorInfo,
  getServicesNoSaveDataInStore,
  getTripAdvisorServices,
  getService,
  getServicePublicId,
  getServiceCatalogues,
  getServiceItems,
  getServiceItem,
  getServiceItemCategories,
  getCategories,
  getCategory,
  getCategoryCalendar,
  getShowsCalendar,
  getProduct,
  getProducts,
  getProductAttributes,
  getProductCategories,
  reserveWithProduct,
  reserveService,
  joinActivity,
  joinActivityFromPWA,
  getBookingForm,
  leadWithProduct,
  getBookingConfig,
  getMapPoints,
  getMapCategories,
  // getCongressActivity,
  getCongressCalendar,
  getUrls,
  getDeepLinks,
  getDeepLinkData,
  sendPostToUrl,
  externalWebBooking,
  sendGetToUrl,
  getFrontpageIcon,
  storeChatMessages,
  addChatMessage,
  removeLastChatMessage,
  getSurvey,
  getSurveys,
  getSurveyQuestions,
  sendSurvey,
  sendSurveyV2,
  getSurveysV2Replies,
  getSurveysExternalReviewsConfiguration,
  hideSurvey,
  saveWelcomeSurveyResponseDate,
  getChatToken,
  setPendingTermsConditions,
  linkCongress,
  unlinkCongress,
  getEventList,
  getEventInfo,
  getCongressList,
  getCongress,
  getCongressActivityDetail,
  getFieldsLoginPms,
  getLoginType,
  createLoginPMS,
  decodeEstablishmentHash,
  decodeEstablishmentHashAndStoreData,
  decodeCongressHash,
  sendStatusAlert,
  getEventsCalendar,
  findEnabledModules,
  searchElastic,
  searchElasticPredefined,
  searchElasticAllServicesOrProducts,
  getClusterEstablishmentId,
  getEstablishmentAssociatedApps,
  sendCommentToTransaction,
  getTransactionDetail,
  cancelBooking,
  searchWit,
  setGroupingTab,
  setCityGuideTab,
  setGroupingPointIndex,
  setGroupingView,
  setCityGuideView,
  resetGrouping,
  getEstablishmentWeather,
  getPrestayUrlInfo,
  createNewEnquiry,
  subscriptionActive,
  unlockAppWithCode,
  getCredencialDigitalKey,
  sendLogDigitalKey,
  getDigitalKey,
  storeOfflineMessage,
  getCorporateContents,
  getDateNow,
  getSpaceReservationFields,
  sendSpacePostToUrl,
  getSpaceConfigurationByService,
  getSpaceConfigurationByType,
  setServiceCatalogueSegmentationWatched,
  getSpaceRestrictionsByService,
  getSpaceMultiserviceRestrictionsByService,
  getSpaceReservationFieldsOrder,
  getSpaceInterval,
  getSpaceReservationList,
  getSpaceByService,
  getSpaceServiceSummary,
  getSpaceDaySchedule,
  getSpaceDayScheduleServiceList,
  getSpaceCombinationInfo,
  getSpaceScheduleDays,
  cancelSpaceBooking,
  setDirectoryTab,
  getGDPRConfiguration,
  getPaymentPage,
  getPaymentIntent,
  getSpaceOrder,
  addPromoAsSeen,
  getSpacesTurns,
  getServiceTypeConfigurationByType,
  setLanguage,
  getGuestConsents,
  updateGuestConsents,
  setPSCode,
  setAppWelcomeShow,
  setWelcomeShowActive,
  setStatuswelcomeShowVisible,
  getCampaignUrl,
  getMultiEstablishment,
  setServiceOrderingFunnelCache,
  deleteServiceOrderingFunnelCache,
  paymentsEnabledForService,
  getAppDataByApiKey,
};
