import md5 from "md5";
import merge from "lodash-es/merge";
import union from "lodash-es/union";
import * as utils from "../services/utils";
import config from "@/config";
import initialState from "./initialState";
import orderBy from "lodash-es/orderBy";
import * as Sentry from "@sentry/vue";

const RESET_STORE = state => {
  const initState = initialState();
  Object.keys(initState).forEach(key => {
    state[key] = initState[key];
  });
};

const UPDATE_MAIN_COLOR = (state, mainColor) => {
  state.mainColor = `#${mainColor.replace("#", "")}`;

  var url_string = window.location.href;
  var url = new URL(url_string);
  if (url.searchParams.get("color")) {
    state.mainColor = `#${url.searchParams.get("color")}`;
  }
  const meta = document.createElement("meta");
  meta.name = "theme-color";
  meta.content = state.mainColor;
  document.getElementsByTagName("head")[0].appendChild(meta);
};

const IS_FROM_PWA = (state, isFromPWA) => {
  state.isFromPWA = isFromPWA;
  if (!state.deviceUid) {
    state.deviceUid = md5(new Date().getTime() + ":" + state.establishment.id);
  }
};

const SET_VERTICAL_SCREEN = (state, vertical) => {
  state.verticalScreen = vertical;
};

const SET_VERTICAL_SCREEN_WITH_VIDEO = (state, vertical) => {
  state.verticalScreenWithVideo = vertical;
};

const IS_LOBBY = (state, isLobby) => {
  state.isLobby = isLobby;
};

const IS_TV = (state, isTV) => {
  state.isTV = isTV;
};

const IS_DESKTOP = (state, isDesktop) => {
  state.isDesktop = isDesktop;
};

const SHOW_DESKTOP_CHAT_SCREEN = (state, showDesktopChatScreen) => {
  state.showDesktopChatScreen = showDesktopChatScreen;
};

const USER_USED_CHAT = (state, userUsedChat) => {
  state.userUsedChat = userUsedChat;
};

const CMS_MODE = (state, isDesktop) => {
  state.cmsMode = isDesktop;
};

const IS_EMBEDDED_CHAT = (state, isEmbeddedChat) => {
  state.isEmbeddedChat = isEmbeddedChat;
};

const EMBEDDED_RETURN_URL = (state, embeddedReturnURL) => {
  state.embeddedReturnURL = embeddedReturnURL;
};

const ADD_TO_HOME = (state, addToHomeAlreadyShown) => {
  state.addToHomeAlreadyShown = addToHomeAlreadyShown;
};

const CHANGE_DEVICE_UID = (state, data) => {
  if (data) {
    state.deviceUid = data;
  } else {
    state.deviceUid = md5(new Date().getTime() + ":" + state.establishment.id);
  }
};

const SET_EMBEDDED_TYPE = (state, data) => {
  state.embeddedType = data;
};

const IS_MONO_ESTABLISHMENT_APP = (state, data) => {
  state.isMonoEstablishmentApp = data;
};

const SET_PUSH_TOKEN = (state, data) => {
  state.pushToken = data;
};

const CHANGE_ESTABLISHMENT = (state, { establishmentId }) => {
  if (state.establishment.id === establishmentId) return;

  state.twilioTokenData = undefined;
  state.establishment.name = undefined;
  state.establishment.commercialEntity = undefined;
  state.establishment.id = establishmentId;
  state.user ? (state.user.establishmentId = establishmentId) : "";
  INTERFACE_LANGUAGE(state, false);
};

const SET_ESTABLISHMENT_HASH = (state, establishmentHash) => {
  Sentry.setTag("establishmentHash", establishmentHash);
  state.establishmentHash = establishmentHash;
};

const SET_TOKEN_USER = (state, token) => {
  try {
    if (token && state.establishment.id != undefined && state.establishment.id != null) {
      if (state.tokenUserArray != undefined) {
        state.tokenUserArray[state.establishment.id] = token;
      } else {
        state.tokenUserArray = {};
        state.tokenUserArray[state.establishment.id] = token;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const SET_DIGITAL_KEY_FIELD = (state, FieldsKey) => {
  try {
    if (FieldsKey.length > 0 && state.establishment.id != undefined && state.establishment.id != null) {
      if (state.digitalKeyFieldArray != undefined) {
        state.digitalKeyFieldArray[state.establishment.id] = FieldsKey;
      } else {
        state.digitalKeyFieldArray = {};
        state.digitalKeyFieldArray[state.establishment.id] = FieldsKey;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const CHANGE_API_KEY = (state, apiKey) => {
  state.apiKey = apiKey;
};

const CHANGE_OS = (state, os) => {
  state.os = os;
};

const CHANGE_APP_ID = (state, appId) => {
  state.appId = appId;
};

const CHANGE_NOTIFICATIONS = (state, notifications) => {
  state.notifications = notifications;
};

const DELETE_NOTIFICATION = (state, notificationId) => {
  state.notifications = state.notifications.filter(e => e.data.id !== notificationId);
};

const DISMISS_NOTIFICATION = (state, notificationId) => {
  state.notifications.forEach(notification => {
    if (notification.data.id === notificationId) {
      notification.alreadyShown = true;
    }
  });
};

const DISMISS_NOTIFICATIONS = state => {
  state.notifications.forEach(notification => {
    notification.alreadyShown = true;
  });
};

const DELETE_NOTIFICATION_ONLY_ONE = (state, notificationId) => {
  var hash = {};
  state.notifications = state.notifications.filter(function (current) {
    var exists = !hash[current.data.id];
    hash[current.data.id] = true;
    return exists;
  });
  state.notifications.forEach(notification => {
    if (notification.data.id === notificationId) {
      notification.alreadyShown = true;
    }
  });
};

const UPDATE_USER = (state, changedAttributes) => {
  const keys = Object.keys(changedAttributes);
  keys.forEach(key => (state.user[key] = changedAttributes[key]));
};

const LOAD_ESTABLISHMENT = (state, establishmentData) => {
  state.establishment = establishmentData;
};

const HAS_ENTERED = (state, establishmentId) => {
  if (state.user.establishments[establishmentId]) {
    state.user.establishments[establishmentId].hasEntered = true;
  } else {
    state.user.establishments[establishmentId] = {
      hasEntered: true
    };
  }
};

const CREATE_VISITOR = (state, visitorData) => {
  if (!state.user || state.user.length === 0) {
    state.user = {};
  }
  state.user = merge(state.user, visitorData);

  if (
    state.user.establishments &&
    state.user.establishments[state.establishment.id] &&
    (state.user.establishments[state.establishment.id].room || state.user.establishments[state.establishment.id].cliId)
  ) {
    if (state.user.establishments[state.establishment.id].name) {
      state.user.name = state.user.establishments[state.establishment.id].name;
    }
    if (state.user.establishments[state.establishment.id].room) {
      state.user.room = state.user.establishments[state.establishment.id].room;
    }
    if (state.user.establishments[state.establishment.id].checkIn) {
      state.user.checkIn = state.user.establishments[state.establishment.id].checkIn;
    }
    if (state.user.establishments[state.establishment.id].checkOut) {
      state.user.checkOut = state.user.establishments[state.establishment.id].checkOut;
    }
    if (state.user.establishments[state.establishment.id].cliId) {
      state.user.cliId = state.user.establishments[state.establishment.id].cliId;
    }
  }
  if (
    visitorData &&
    visitorData.establishments &&
    visitorData.establishments[state.establishment.id] &&
    visitorData.establishments[state.establishment.id].userType === "VISITOR"
  ) {
    delete state.user.room;
    delete state.user.checkIn;
    delete state.user.checkOut;
    delete state.user.cliId;
  }
};

const SET_ACTIVITY = (state, activityData) => {
  state.user.activity = activityData;
};

const LOGOUT = (state, visitorData) => {
  state.user = visitorData;
  state.userInbox = undefined;
  state.surveyReplies = {};
  state.allSurveys = {};
  //state.allSurveysShow = {};
  // if (state.mySchedule && state.mySchedule[state.establishment.id]) {
  //   delete state.mySchedule[state.establishment.id];
  // }
  state.miniclubTutorInfo = {};
  state.myVisitorBookings = {};
  try {
    if (state.hasOwnProperty("tokenUserArray") && state.tokenUserArray[state.establishment.id] != undefined) {
      state.tokenUserArray[state.establishment.id] = "";
    }
    if (
      state.hasOwnProperty("digitalKeyFieldArray") &&
      state.digitalKeyFieldArray[state.establishment.id] != undefined
    ) {
      state.digitalKeyFieldArray[state.establishment.id] = [];
    }
  } catch (e) {
    console.error(e);
  }
};

const SET_USERKEY = (state, userkey) => {
  state.user ? (state.user.userKey = userkey) : "";
};

const USER_INBOX = (state, inbox) => {
  let storedInbox = state.userInbox;
  let firstDownload = false;
  if (!storedInbox || (storedInbox && Object.keys(storedInbox).length === 0)) {
    storedInbox = Object.assign({}, inbox);
    delete storedInbox.bookings;
    delete storedInbox.requests;
    delete storedInbox.orders;
    delete storedInbox.alertsTimetable;
    delete storedInbox.surveysAnswers;
    delete storedInbox.surveys;
    firstDownload = true;
  }

  let storedAlerts = storedInbox.alerts;
  if (!storedAlerts) {
    storedAlerts = {};
  }

  const newAlerts = inbox.alerts;

  if (newAlerts) {
    const newAlertKeys = Object.keys(newAlerts);
    if (newAlertKeys.length > 0) {
      newAlertKeys.forEach(function (key) {
        if (!storedAlerts[key] || firstDownload) {
          const alert = newAlerts[key];
          alert.unread = 1;
          storedAlerts[key] = alert;
        }
      });
    }
  }

  let storedTimetable = storedInbox.alertsTimetable;
  const newTimetable = inbox.alertsTimetable;
  if (!storedTimetable) {
    storedTimetable = {};
  }

  if (newTimetable && Object.keys(newTimetable)) {
    const days = Object.keys(newTimetable);
    days.forEach(function (day) {
      const times = newTimetable[day];
      Object.keys(times).forEach(function (time) {
        const key = day + " " + time;
        const alertIds = times[time];
        let resultArray = [];

        if (storedTimetable[key]) {
          resultArray = storedTimetable[key];
        }

        storedTimetable[key] = union(resultArray, alertIds);
      });
    });
  }

  storedInbox.alertsTimetable = storedTimetable;

  storedInbox.bookings = storedInbox.bookings || {};

  if (inbox.bookings) {
    inbox.bookings.forEach(booking => {
      storedInbox.bookings[booking.id] = booking;
    });
  }

  storedInbox.orders = storedInbox.orders || {};

  if (inbox.orders) {
    Object.values(inbox.orders).forEach(order => {
      storedInbox.orders[order.id] = order;
    });
  }

  storedInbox.surveysAnswers = storedInbox.surveysAnswers || {};

  if (inbox.surveysAnswers) {
    Object.keys(inbox.surveysAnswers).forEach(key => {
      const answers = inbox.surveysAnswers[key];
      storedInbox.surveysAnswers[key] = answers;
    });
  }

  storedInbox.surveys = storedInbox.surveys || {};

  if (inbox.surveys) {
    Object.keys(inbox.surveys).forEach(key => {
      const surveys = inbox.surveys[key];
      storedInbox.surveys[key] = surveys;
    });
  }

  storedInbox.requests = storedInbox.requests || {};

  if (inbox.requests) {
    inbox.requests.forEach(request => {
      storedInbox.requests[request.id] = request;
    });
  }

  storedInbox.alerts = storedAlerts;
  storedInbox.lastUpdate = inbox.lastUpdate;
  storedInbox.establishments = inbox.establishments;
  state.userInbox = Object.assign({}, storedInbox);
};

const UPDATE_REQUEST = (state, request) => {
  let storedInbox = state.userInbox;
  if (!storedInbox) {
    storedInbox = {};
  }
  storedInbox.requests = storedInbox.requests || {};

  if (request && request.id) {
    storedInbox.requests[request.id] = request;
  }
  state.userInbox = Object.assign({}, storedInbox);
};

const MARK_AS_READ = (state, alertId) => {
  const storedInbox = state.userInbox;
  if (!storedInbox || !storedInbox.alerts) {
    return;
  }
  const storedAlerts = storedInbox.alerts;
  if (!storedAlerts[alertId]) {
    return;
  }
  const alert = storedAlerts[alertId];
  alert.unread = 0;
  storedAlerts[alertId] = alert;
  storedInbox.alerts = storedAlerts;
  state.userInbox = storedInbox;
};

const DELETE_ALERT = (state, alertId) => {
  const storedInbox = state.userInbox;
  if (!storedInbox || !storedInbox.alerts) {
    return;
  }
  const storedAlerts = storedInbox.alerts;
  if (!storedAlerts[alertId]) {
    return;
  }
  delete storedAlerts[alertId];
  storedInbox.alerts = Object.assign({}, storedAlerts);
  state.userInbox = Object.assign({}, storedInbox);
};

const CREATE_GUEST = (state, guestData) => {
  state.allSurveysShow = {};
  if (
    !state.user?.checkIn ||
    !guestData?.establishments[guestData.establishmentId + ""]?.checkIn ||
    !state.user.checkIn === guestData.establishments[guestData.establishmentId + ""].checkIn ||
    !state.user.checkOut === guestData.establishments[guestData.establishmentId + ""].checkOut
  ) {
    state.userInbox = {};
    state.myVisitorBookings = {};
  }
  state.miniclubTutorInfo = {};
  state.user = Object.assign(state.user, guestData);
};

const GET_STRINGS = (state, strings) => {
  state.strings = strings;
};

const GET_SERVICES = (state, data) => {
  let storedServices = state.services;
  state.services = {};
  if (!storedServices) {
    storedServices = {};
  }
  storedServices[data.serviceType] = data.services;
  if (state.categories[data.serviceType]) {
    storedServices[data.serviceType].sort(function (a, b) {
      const categories = state.categories[data.serviceType];
      let aCategoryIndex = 0;
      let bCategoryIndex = 0;
      categories.forEach((category, index) => {
        if (category.id == a.category) {
          aCategoryIndex = index;
        }
        if (category.id == b.category) {
          bCategoryIndex = index;
        }
      });
      return aCategoryIndex - bCategoryIndex;
    });
  }
  state.services = storedServices;
};

const GET_SERVICE_ITEMS = (state, data) => {
  let storedServices = state.serviceItems;
  state.serviceItems = {};
  if (!storedServices) {
    storedServices = {};
  }
  storedServices[data.serviceId] = data.serviceItems;
  state.serviceItems = storedServices;
};

const GET_SERVICE_ITEM_CATEGORIES = (state, data) => {
  let storedServices = state.serviceItemCategories;
  state.serviceItemCategories = {};
  if (!storedServices) {
    storedServices = {};
  }
  storedServices[data.serviceId] = data.serviceItemCategories;
  state.serviceItemCategories = storedServices;
};

const GET_CATEGORIES = (state, data) => {
  let storedCategories = state.categories;
  state.categories = {};
  if (!storedCategories) {
    storedCategories = {};
  }
  const sortedCategories = orderBy(data.categories, "categoryOrder", "asc");
  storedCategories[data.serviceType] = sortedCategories;
  state.categories = storedCategories;
};

const GET_TRIPADVISOR_SERVICES = (state, data) => {
  let storedExternalServices = state.externalServices;
  state.externalServices = {};
  if (!storedExternalServices) {
    storedExternalServices = {};
  }
  storedExternalServices.tripAdvisorServices = data;
  state.externalServices = storedExternalServices;
};

const GET_PRODUCTS = (state, data) => {
  let storedProducts = state.products;
  state.products = {};
  if (!storedProducts) {
    storedProducts = {};
  }
  storedProducts[data.serviceId] = data.products;
  state.products = storedProducts;
};

const GET_PRODUCT_CATEGORIES = (state, data) => {
  let storedProductCategories = state.productCategories;
  state.productCategories = {};
  if (!storedProductCategories) {
    storedProductCategories = {};
  }
  storedProductCategories[data.serviceId] = data.categories;
  state.productCategories = storedProductCategories;
};

const DOWNLOAD_BANNER = (state, data) => {
  state.downloadBanner = data;
};

const FRONTPAGE_ICON = (state, data) => {
  state.frontpageIcon = data;
  var url_string = window.location.href;
  var url = new URL(url_string);
  if (url.searchParams.get("icon")) {
    state.frontpageIcon = url.searchParams.get("icon");
  }
};

const STORE_CHAT_MESSAGES = (state, data) => {
  state.chatMessages = data;
};

const ADD_CHAT_MESSAGE = (state, data) => {
  state.chatMessages.push(data);
};

const REMOVE_LAST_MESSAGE = (state, data) => {
  state.chatMessages.pop();
};

const SET_SURVEYS_SHOW = (state, data) => {
  const surveys = state.allSurveysShow ?? {};
  surveys[data.establishment] = surveys[data.establishment] ?? {};

  surveys[data.establishment][data.id] =
    data.triggerType === "transactional"
      ? {
          ...surveys[data.establishment][data.id],
          [data.transationalId]: data
        }
      : data;

  state.allSurveysShow = surveys;
};

const SURVEY = (state, data) => {
  let surveys = {};
  if (state.surveys) {
    surveys = state.surveys;
  }
  if (data && data.id) {
    surveys[data.id] = data;
  }
  state.surveys = surveys;
};

const SURVEYS = (state, data) => {
  const surveys = state.allSurveys ?? {};
  surveys[state.clusterEstablishmentId] = {};
  data.forEach(survey => (surveys[survey.establishment][survey._id] = survey));
  state.allSurveys = surveys;
};

const SURVEY_REPLIES = (state, data) => {
  let replies = {};
  if (state.surveyReplies) {
    replies = state.surveyReplies;
  }
  replies[state.clusterEstablishmentId] = data;
  state.surveyReplies = replies;
};

const SURVEY_EXTERNAL_REVIEWS = (state, data) => {
  let surveyExternalReviewsConfiguration = {};
  if (state.surveyExternalReviewsConfiguration) {
    surveyExternalReviewsConfiguration = state.surveyExternalReviewsConfiguration;
  }
  if (data.externalReviewsConfig) {
    surveyExternalReviewsConfiguration[state.clusterEstablishmentId] = data.externalReviewsConfig;
  }
  state.surveyExternalReviewsConfiguration = surveyExternalReviewsConfiguration;
};

const SURVEY_EXTERNAL_REVIEWS_ALREADY_SHOWN = state => {
  let surveyExternalReviewsAlreadyShown = {};
  if (state.surveyExternalReviewsAlreadyShown) {
    surveyExternalReviewsAlreadyShown = state.surveyExternalReviewsAlreadyShown;
  }
  surveyExternalReviewsAlreadyShown[state.clusterEstablishmentId] = true;
  state.surveyExternalReviewsAlreadyShown = surveyExternalReviewsAlreadyShown;
};

const SURVEY_SHOW_FRONTPAGE = (state, data) => {
  state.surveysFrontpageShow = data;
};

const STORE_TWILIO_TOKEN = (state, data) => {
  state.twilioTokenData = data;
};

const PENDING_TERMS_CONDITIONS = (state, data) => {
  state.pendingTermsConditions[data.establishment] = data.value;
};

const CUSTOM_SERVICES = (state, data) => {
  let custom = [];
  const services = state.services;
  if (state.services && state.services.custom) {
    custom = services.custom;
  }

  const foundItem = custom.filter(function (item) {
    return item.id === data.id;
  });
  if (!foundItem || foundItem.length === 0) {
    custom.push(data);
    services.custom = custom;
  }

  state.services = services;
};

//value only has one event
const EVENTS = (state, value) => {
  let storedEvents = state.congressEvents;
  let added = false;
  if (storedEvents) {
    Object.keys(storedEvents).forEach(key => {
      const answers = storedEvents[key];
      if (value.id) {
        if (key == value.id) {
          storedEvents[key] = utils.getUpdateEvent(value);
          added = true;
        }
      }
    });
  } else {
    storedEvents = {};
  }
  if (added == false) {
    storedEvents[value.id] = value;
  }

  state.congressEvents = storedEvents;
};

const REMOVE_EVENT = (state, value) => {
  const storedEvents = state.congressEvents;
  const filteredEvents = {};

  if (storedEvents[value]) {
    Object.keys(storedEvents).forEach(key => {
      if (key != value) {
        filteredEvents[key] = storedEvents[key];
      }
    });
  }
  state.congressEvents = filteredEvents;
};

const PUBLIC_EVENTS = (state, value) => {
  state.publicEvents = value;
};
const NEW_EVENT_ID = (state, value) => {
  state.newEventId = value;
};
const DESKTOP_RIGHT_PANEL_HISTORY = (state, value) => {
  state.desktopRightPanelHistory = value;
};

const HIDE_SURVEY = (state, value) => {
  state.hiddenSurveys[value] = true;
};

const SET_WELCOME_SURVEY_DATE = (state, value) => {
  state.welcomeSurveyResponseDate = value;
};

const SET_BOT_TOKEN = (state, value) => {
  state.botToken = value;
};

const SET_CMS_TOKEN = (state, value) => {
  state.cmsToken = value;
};
const CONGRESS_ID_REDIRECT = (state, value) => {
  state.congressIdRedirect = value;
};

const EVENTS_CALENDAR = (state, data) => {
  let eventsCalendar = state.eventsCalendar;
  if (!eventsCalendar) {
    eventsCalendar = {};
  }
  if (data.idCategory && data.data) {
    eventsCalendar[data.idCategory + ""] = data.data;
  }

  state.eventsCalendar = eventsCalendar;
};

const TOKEN_API_CLUSTER = (state, value) => {
  state.tokenApiCluster = value;
};

const TOKEN_REFRESH_API_CLUSTER = (state, value) => {
  state.tokenRefreshApiCluster = value;
};

const ENABLED_MODULES = (state, modules) => {
  state.enabledModules = modules;
};

const DYNAMIC_FRONTPAGE = (state, data) => {
  if (!data.key || !data.data) {
    return;
  }
  let dataInEstablishment = state.dynamicFrontpage[state.establishment.id];
  if (!dataInEstablishment) {
    dataInEstablishment = {};
  }
  dataInEstablishment[data.key] = data.data;
  state.dynamicFrontpage[state.establishment.id] = dataInEstablishment;
};

const ELASTIC_RESULTS = (state, data) => {
  if (!data || !data.results) {
    return;
  }
  state.elasticResults = [data];
};

const ESTABLISHMENT_ID_CLUSTER = (state, id) => {
  state.clusterEstablishmentId = id;
};

const APP_IS_DISABLED = (state, disabled) => {
  state.appIsDisabled = disabled;
};

const SMART_SEARCH_TEXT = (state, text) => {
  state.smartSearchText = text;
};

const SET_GROUPING_TAB = (state, tab) => {
  state.grouping.selectedTab = tab;
};

const SET_CITY_GUIDE_TAB = (state, tab) => {
  state.cityGuide.selectedTab = tab;
};

const SET_GROUPING_POINT_INDEX = (state, index) => {
  state.grouping.selectedPointIndex = index;
};

const NOTIFICATIONS_TIMES_SHOWED = (state, times) => {
  state.notificationsTimesShowed = times;
};
const NOTIFICATIONS_TIMESTAMP_POPUP = (state, ts) => {
  state.notificationsTimestampPopup = ts;
};

const INBOX_TIMES_SHOWED = (state, times) => {
  state.inboxPopupTimesShowed = times;
};
const INBOX_TIMESTAMP_POPUP = (state, ts) => {
  state.inboxTimestampPopup = ts;
};

const SET_GROUPING_VIEW = (state, view) => {
  state.grouping.selectedView = view;
};

const SET_CITY_GUIDE_VIEW = (state, view) => {
  state.cityGuide.selectedView = view;
};

const RESET_GROUPING = state => {
  state.grouping = {
    selectedView: false,
    selectedTab: false,
    selectedPointIndex: false
  };
};

const ADD_ITEM_TO_MY_SCHEDULE = (state, data) => {
  const date = data.eventDay;
  const time = data.hour;
  const service = data.service;
  if (!date || !time || !service) {
    return;
  }
  if (!state.mySchedule) {
    state.mySchedule = {};
  }
  if (!state.mySchedule[state.establishment.id]) {
    state.mySchedule[state.establishment.id] = {};
  }
  state.mySchedule[state.establishment.id][date + "-" + time + "-" + service] = data;
};

const REMOVE_ITEM_FROM_MY_SCHEDULE = (state, data) => {
  const date = data.eventDay;
  const time = data.hour;
  const service = data.service;
  if (state.establishment.id && state.mySchedule[state.establishment.id]) {
    delete state.mySchedule[state.establishment.id][date + "-" + time + "-" + service];
  }
};

const ADD_ITEM_TO_MY_VISITOR_BOOKINGS = (state, { data, deleteItem }) => {
  const id = data.id;
  if (!id) {
    return;
  }
  if (!state.myVisitorBookings) {
    state.myVisitorBookings = {};
  }
  if (state.myVisitorBookings[id] && deleteItem) {
    state.myVisitorBookings[id].status = 2;
  } else {
    state.myVisitorBookings[id] = data;
  }
};

const DELETE_INFO_ESTABLISHMENT = state => {
  state.services = {};
  state.categories = {};
  state.externalServices = {};
  state.products = {};
  state.productCategories = {};

  if (state.frontpage) {
    state.frontpage.data = {};
  }

  state.eventsCalendar = {};

  state.notifications = [];
  state.userInbox = {}; //should we delete it if we change?

  state.grouping = {};
  state.miniclubTutorInfo = {};
  state.myVisitorBookings = {};
  INTERFACE_LANGUAGE(state, false);
};

const NEW_CONGRESS = (state, newCongress) => {
  state.newCongress = newCongress;
};

const SET_LAST_READ_REQUEST_CHAT_MESSAGES = (state, data) => {
  if (!state.requestChatLastReadMessages) {
    state.requestChatLastReadMessages = {};
  }

  if (data.lastReadMessageIndex && data.id) {
    state.requestChatLastReadMessages[data.id] = data.lastReadMessageIndex;
  }
};

const STORE_ONE_SERVICE = (state, data) => {
  if (!data.service || !data.serviceType) {
    return;
  }
  let servicesFromServiceType = [];
  const services = state.services;
  if (state.services && state.services[data.serviceType]) {
    servicesFromServiceType = services[data.serviceType];
  }

  const foundItem = servicesFromServiceType.filter(function (item) {
    return item.id === data.service.id;
  });
  if (!foundItem || foundItem.length === 0) {
    servicesFromServiceType.push(data.service);
    services[data.serviceType] = servicesFromServiceType;
  } else {
    const index = servicesFromServiceType.indexOf(foundItem[0]);
    if (index !== -1) {
      servicesFromServiceType.splice(index, 1, data.service);
      services[data.serviceType] = servicesFromServiceType;
    }
  }

  state.services = services;
};

const ROOM_SERVICE_CART_CACHE = (state, value) => {
  state.roomServiceCartCache = value;
};

const SERVICE_ORDERING_FUNNEL_CACHE = (state, data) => {
  if (!data.serviceId || !data.cart) {
    return;
  }
  if (!state.serviceOrderingFunnelCache) {
    state.serviceOrderingFunnelCache = {};
  }
  state.serviceOrderingFunnelCache[data.serviceId] = data;
};

const DELETE_SERVICE_ORDERING_FUNNEL_CACHE = (state, serviceId) => {
  if (!state.serviceOrderingFunnelCache || !serviceId) {
    return;
  }
  delete state.serviceOrderingFunnelCache[serviceId];
};

const APP_WELCOME_SHOW = (state, param) => {
  state.welcomeShow = { ...state.welcomeShow, ...param };
};

const WELCOME_SHOW_VISIBLE = (state, param) => {
  state.welcomeShowVisible = { ...state.welcomeShowVisible, ...param };
};

const WELCOME_SHOW_ACTIVE = (state, param) => {
  state.welcomeShowActive = { ...state.welcomeShowActive, ...param };
};

const DEEPLINK_GOTOSECTION = (state, param) => {
  state.deeplinkGoToSection = param;
};

const STORE_FRONTPAGE_DATA = (state, frontpageData) => {
  frontpageData.level1Nodes = frontpageData.level1_nodes;
  frontpageData.level2Nodes = frontpageData.level2_nodes;
  state.data = frontpageData;
};

const UTM_SOURCE = (state, value) => {
  state.utm_source = value;
};
const UTM_CAMPAIGN = (state, value) => {
  state.utm_campaign = value;
};
const UTM_MEDIUM = (state, value) => {
  state.utm_medium = value;
};

const RESET_USER_STORE = state => {
  state.user = {
    userKey: "",
    deviceKey: "",
    psId: "1052659481528108",
    activity: {}
  };
};

const RESET_APIKEY_TO_DEFAULT = state => {
  state.apiKey = "a97e0983e8fe50d72ca0d6782912d70a";
  state.appId = 76;
  config.apiKey = "a97e0983e8fe50d72ca0d6782912d70a";
  config.appId = 76;
};
const STARTER_PLAN = (state, value) => {
  state.starterPlan = value;
};

const STORE_REQUEST_CHAT_OFFLINE_MESSAGE = (state, data) => {
  const conversationId = data.id;
  const body = data.body;
  if (!conversationId || !body) {
    return;
  }
  if (!state.offlineMessages[conversationId]) {
    state.offlineMessages[conversationId] = [];
  }
  state.offlineMessages[conversationId].push(body);
};

const STORE_CATALOGUES = (state, data) => {
  if (!data.serviceId || !data.catalogues) {
    return;
  }
  if (!state.catalogues) {
    state.catalogues = {};
  }
  state.catalogues[data.serviceId] = data.catalogues;
};

const CATALOGUES_SEGMENTATION = (state, data) => {
  if (!data.publicId) {
    return;
  }
  if (!state.cataloguesSegmentation) {
    state.cataloguesSegmentation = [];
  }

  if (state.cataloguesSegmentation.length) {
    const found = state.cataloguesSegmentation.find(id => id === data.publicId);
    if (found) {
      return;
    }
  }

  state.cataloguesSegmentation.push(data.publicId);
};

const ADD_CITY_GUIDE_BOOKMARK = (state, data) => {
  let bookmarks = {};
  if (state.cityGuide.bookmarks) {
    bookmarks = state.cityGuide.bookmarks;
  }
  const thisBookmark = {
    service: data.service
  };
  if (data.note) {
    thisBookmark.note = data.note;
  }
  bookmarks[data.service.id] = thisBookmark;
  state.cityGuide.bookmarks = bookmarks;
};

const REMOVE_CITY_GUIDE_BOOKMARK = (state, service) => {
  let bookmarks = {};
  if (state.cityGuide.bookmarks) {
    bookmarks = state.cityGuide.bookmarks;
  }
  delete bookmarks[service.id];
  state.cityGuide.bookmarks = bookmarks;
};

const REMOVE_CITY_GUIDE_BOOKMARK_NOTE = (state, serviceId) => {
  let bookmarks = {};
  if (state.cityGuide.bookmarks) {
    bookmarks = state.cityGuide.bookmarks;
  }
  const bookmark = bookmarks[serviceId];
  delete bookmark.note;
  bookmarks[serviceId] = bookmark;
  state.cityGuide.bookmarks = bookmarks;
};

const PRESTAY_CODE = (state, code) => {
  state.preStayCode = code;
};

const SET_DIRECTORY_TAB = (state, tab) => {
  state.directory.selectedTab = tab;
};

const SET_MINICLUB_TUTOR_INFO = (state, info) => {
  state.miniclubTutorInfo = info;
};
const SET_GDPR_CONFIGURATION = (state, data) => {
  state.gdprConfiguration = data;
};

const SET_PROMO_HAS_BEEN_SEEN = (state, promoId) => {
  if (!state.shownPromotions.includes(promoId)) {
    state.shownPromotions.push(promoId);
  }
};
const INTERFACE_LANGUAGE = (state, language) => {
  state.interfaceLanguage = language;
};

const SET_TRACKER_MODULE = (state, data) => {
  const module = data.module;
  state.infoTracker[module] = data;
};

const DELETE_TRACKER_MODULE = (state, module) => {
  if (state.infoTracker && state.infoTracker[module]) {
    delete state.infoTracker[module];
  }
};

const SET_APP_URLS = (state, urls) => {
  state.appUrls = urls;
};

const SET_DATE_NOW = (state, dateNow) => {
  state.dateNow = dateNow;
};

const SET_USER_LOCALE = (state, locale) => {
  state.user.locale = locale;
};

const STORE_FRONTPAGE_SCROLL = (state, scroll) => {
  state.frontpageScroll = scroll;
};

const STORE_SHOW_OPEN_OBJECT = (state, showOpenObject) => {
  let object = {};
  if (state.showOpenObject) {
    object = state.showOpenObject;
  }
  Object.keys(showOpenObject).forEach(key => {
    const value = showOpenObject[key];
    object[key] = value;
  });
  state.showOpenObject = object;
};

const EMBED_SAFEAREA = (state, size) => {
  state.embedSafearea = size;
};

const ADD_EVENT_PRE_LOGIN = (state, event) => {
  state.eventPreLogin = event;
};

const SET_MULTI_ESTABLISHMENT = (state, data) => {
  state.multiEstablishment = data;
};

const SET_MULTI_ESTABLISHMENT_PUBLIC_ID = (state, data) => {
  state.multiEstablishmentPublicId = data;
};

const SET_SCHEDULES_SERVICES = (state, data) => {
  state.scheduleServices = data;
};

const SET_POLICY_TEXT = (state, data) => {
  state.policyAndText = data;
};

export {
  RESET_STORE,
  UPDATE_MAIN_COLOR,
  IS_FROM_PWA,
  SET_VERTICAL_SCREEN,
  SET_VERTICAL_SCREEN_WITH_VIDEO,
  IS_EMBEDDED_CHAT,
  ADD_TO_HOME,
  EMBEDDED_RETURN_URL,
  CHANGE_DEVICE_UID,
  SET_PUSH_TOKEN,
  SET_EMBEDDED_TYPE,
  IS_MONO_ESTABLISHMENT_APP,
  CHANGE_ESTABLISHMENT,
  SET_ESTABLISHMENT_HASH,
  SET_TOKEN_USER,
  SET_DIGITAL_KEY_FIELD,
  CHANGE_API_KEY,
  CHANGE_APP_ID,
  LOAD_ESTABLISHMENT,
  CREATE_VISITOR,
  HAS_ENTERED,
  CREATE_GUEST,
  GET_STRINGS,
  GET_SERVICES,
  GET_SERVICE_ITEMS,
  GET_SERVICE_ITEM_CATEGORIES,
  UPDATE_USER,
  GET_TRIPADVISOR_SERVICES,
  GET_CATEGORIES,
  SET_ACTIVITY,
  GET_PRODUCTS,
  LOGOUT,
  GET_PRODUCT_CATEGORIES,
  DELETE_NOTIFICATION,
  DELETE_ALERT,
  CHANGE_NOTIFICATIONS,
  DISMISS_NOTIFICATION,
  DISMISS_NOTIFICATIONS,
  DELETE_NOTIFICATION_ONLY_ONE,
  SET_MINICLUB_TUTOR_INFO,
  USER_INBOX,
  SET_USERKEY,
  UPDATE_REQUEST,
  IS_LOBBY,
  IS_TV,
  IS_DESKTOP,
  SHOW_DESKTOP_CHAT_SCREEN,
  CMS_MODE,
  MARK_AS_READ,
  DOWNLOAD_BANNER,
  FRONTPAGE_ICON,
  STORE_CHAT_MESSAGES,
  ADD_CHAT_MESSAGE,
  REMOVE_LAST_MESSAGE,
  SET_SURVEYS_SHOW,
  SURVEY,
  SURVEYS,
  SURVEY_REPLIES,
  SURVEY_EXTERNAL_REVIEWS,
  SURVEY_EXTERNAL_REVIEWS_ALREADY_SHOWN,
  SURVEY_SHOW_FRONTPAGE,
  STORE_TWILIO_TOKEN,
  PENDING_TERMS_CONDITIONS,
  CUSTOM_SERVICES,
  EVENTS,
  REMOVE_EVENT,
  PUBLIC_EVENTS,
  NEW_EVENT_ID,
  DESKTOP_RIGHT_PANEL_HISTORY,
  HIDE_SURVEY,
  SET_WELCOME_SURVEY_DATE,
  SET_CMS_TOKEN,
  SET_BOT_TOKEN,
  EVENTS_CALENDAR,
  TOKEN_API_CLUSTER,
  TOKEN_REFRESH_API_CLUSTER,
  ENABLED_MODULES,
  DYNAMIC_FRONTPAGE,
  ELASTIC_RESULTS,
  USER_USED_CHAT,
  ESTABLISHMENT_ID_CLUSTER,
  APP_IS_DISABLED,
  SMART_SEARCH_TEXT,
  SET_GROUPING_TAB,
  SET_CITY_GUIDE_TAB,
  SET_GROUPING_POINT_INDEX,
  SET_GROUPING_VIEW,
  SET_CITY_GUIDE_VIEW,
  RESET_GROUPING,
  ADD_ITEM_TO_MY_SCHEDULE,
  REMOVE_ITEM_FROM_MY_SCHEDULE,
  ADD_ITEM_TO_MY_VISITOR_BOOKINGS,
  CONGRESS_ID_REDIRECT,
  DELETE_INFO_ESTABLISHMENT,
  NOTIFICATIONS_TIMES_SHOWED,
  NOTIFICATIONS_TIMESTAMP_POPUP,
  NEW_CONGRESS,
  SET_LAST_READ_REQUEST_CHAT_MESSAGES,
  STORE_ONE_SERVICE,
  INBOX_TIMES_SHOWED,
  INBOX_TIMESTAMP_POPUP,
  ROOM_SERVICE_CART_CACHE,
  SERVICE_ORDERING_FUNNEL_CACHE,
  DELETE_SERVICE_ORDERING_FUNNEL_CACHE,
  APP_WELCOME_SHOW,
  WELCOME_SHOW_VISIBLE,
  WELCOME_SHOW_ACTIVE,
  PRESTAY_CODE,
  DEEPLINK_GOTOSECTION,
  STORE_FRONTPAGE_DATA,
  UTM_SOURCE,
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  RESET_USER_STORE,
  RESET_APIKEY_TO_DEFAULT,
  STORE_REQUEST_CHAT_OFFLINE_MESSAGE,
  STARTER_PLAN,
  STORE_CATALOGUES,
  CATALOGUES_SEGMENTATION,
  ADD_CITY_GUIDE_BOOKMARK,
  REMOVE_CITY_GUIDE_BOOKMARK,
  REMOVE_CITY_GUIDE_BOOKMARK_NOTE,
  SET_DIRECTORY_TAB,
  SET_GDPR_CONFIGURATION,
  SET_PROMO_HAS_BEEN_SEEN,
  INTERFACE_LANGUAGE,
  SET_TRACKER_MODULE,
  DELETE_TRACKER_MODULE,
  SET_APP_URLS,
  CHANGE_OS,
  SET_DATE_NOW,
  SET_USER_LOCALE,
  STORE_FRONTPAGE_SCROLL,
  STORE_SHOW_OPEN_OBJECT,
  ADD_EVENT_PRE_LOGIN,
  EMBED_SAFEAREA,
  SET_MULTI_ESTABLISHMENT,
  SET_MULTI_ESTABLISHMENT_PUBLIC_ID,
  SET_SCHEDULES_SERVICES,
  SET_POLICY_TEXT
};
